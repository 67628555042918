import { API_URL } from 'config.js';
import { get, post, upload } from './helpers';

export const resendAmazonEvent = async (payload) => {
    const response = await post(`${API_URL}/amazon-shipments/events`, payload);
    return {
        status: response?.status,
    };
};

export const amz_resubmit_shipment = async (payload) => {
    const response = await post(
        `${API_URL}/amazon-shipments/resubmit`,
        payload
    );
    return {
        status: response?.success,
        ...(await response.json()),
    };
};

export const amz_bulk_resubmit_shipment = async (payload) => {
    const response = await post(
        `${API_URL}/amazon-shipments/bulk-resubmit`,
        payload
    );

    if (!response.ok) {
        return {
            status: response.ok,
        };
    }

    return {
        status: response.ok,
        message: await response.text()
    };
};

export const createAmazonEvent = async (payload) => {
    const response = await post(`${API_URL}/amazon-shipments/events`, payload);
    return {
        status: response?.status,
    };
};

export const get_download_file = async (filename) => {
    const response = await post(
        `${API_URL}/amazon-shipments/download/${filename}`
    );
    return {
        status: response?.status,
        ...(await response.json()),
    };
};

export const get_statuses = async () => {
    const response = await get(
        `${API_URL}/amazon-shipments/statuses`
    );
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};


export const validate_waybills = async (payload) => {
    const response = await post(
        `${API_URL}/amazon-shipments/validate-waybills`,
        payload
    );
    const data = await response.json();
    return data;
};

export const track_send_event_status = async (payload) => {
    const response = await post(
        `${API_URL}/amazon-shipments/track-send_event-status`,
        payload
    );
    const data = await response.json();
    return data;
};

export const outbound_events = async (payload) => {
    const response = await post(
        `${API_URL}/amazon-shipments/outbound-events`,
        payload
    );
    return {
        status: response?.status,
    };
};

export const resendPin = async (payload) => {
    const response = await post(API_URL + "/amazon-shipments/resend-delivery-pin",
        payload
    );
    return {
        status: response?.status,
        ...(await response.json()),
    };
};

export const get_amazon_audit = async (object_id, type) => {
    const response = await get(
        `${API_URL}/events?object_id=${object_id}&type=${type}`
    );
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const add_shipment_notes = async (payload) => {
    const response = await post(API_URL + "/amazon-shipments/shipment-notes",
        payload
    );
    return {
        status: response?.status,
        ...(await response.json()),
    };
};

export const resend_failed_sms = async (payload) => {
    const response = await post(API_URL + "/amazon-shipments/resend-failed-sms",
        payload
    );
    return {
        status: response?.status,
        ...(await response.json()),
    };
};

export const override_event = async (payload) => {
    const response = await post(API_URL + "/amazon-shipments/override-event",
        payload
    );
    return {
        ok: response?.ok,
        ...(await response.json()),
    };
};

export const amazon_dashboard = async (params) => {
    let func = "/amazon-shipments/amazon-dashboard";
    if (params) {
        func = func + params;
    }
    const response = await get(API_URL + func);
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const uploadRouteFile = async (payload) => {
    const response = await upload(`${API_URL}/amazon-shipments/upload_route_file`, payload);
    return {
        status: response.status,
        ...(await response.json()),
    };
};
